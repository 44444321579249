import { createContext } from 'react'

const UserContext = createContext({
  // info from backend
  userId: null,
  email: null,
  role: null,
  agreedToTnC: false,
  sessionId: null,
  name: null,

  // frontend display
  hasLoginError: false,
  errorMessage: '',

  // flow control
  login: () => null,
  logout: () => null,
  verifyToken: () => null,
  agreeToTnC: () => null
})

export default UserContext

export const initialState = {
  userId: null,
  email: null,
  role: null,
  agreedToTnC: false,
  sessionId: null,
  name: null,
}

// determine the next state given 1. current state, 2. new action (, and 3. corresponding payloads)
export const reducer = (state, action) => {
  switch (action.type) {
    // save user info after login success
    case 'login-success': {
      const { userId, email, role, agreedToTnC, sessionId, name} = action.payload
      console.log(`Login success, user is ${name}`)
      return {
        ...state,
        hasLoginError: false,
        userId, email, role, agreedToTnC, sessionId, name,
      }
    }
    /* logout, except involuntary
    show some messages in login page to inform the user why he is kicked out*/
    case 'login-error': {
      const { error:errorMessage } = action.payload
      console.log(`Login error occurred: ${errorMessage}`)
      return {
        ...state,
        hasLoginError: true,
        errorMessage,
        userId: null,
        email: null,
        role: null,
        agreedToTnC: false,
        sessionId: null,
      }
    }
    // logout and forget everything
    case 'logout':
      console.log(`Logout`)
      return {
        ...state,
        hasLoginError: false,
        errorMessage: '',
        userId: null,
        email: null,
        role: null,
        agreedToTnC: false,
        sessionId: null,
      }
    default:
      throw new Error(`Invalid action type: ${action.type}`)
  }
}