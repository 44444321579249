import { createContext, useReducer } from 'react';

import { chatHistoryInitialState, chatHistoryReducer } from '#reducers/chatHistory';


const ChatHistoryContext = createContext(null);


const ChatHistoryProvider = ({ children }) => {
  const [chatHistory, dispatchChatHistory] = useReducer(chatHistoryReducer, chatHistoryInitialState);
  return (
    <ChatHistoryContext.Provider value={ { chatHistory, dispatchChatHistory } }>
      { children }
    </ChatHistoryContext.Provider>
  );
};

export {
  ChatHistoryContext,
  ChatHistoryProvider
};