import React, { useState, useCallback, useEffect, forwardRef, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import * as marked from 'marked';
import { sendFeedback } from '../adapters/restAPIServices';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import aiaLogo from '../assets/aiabot.gif';
import kcashLogo from '../assets/kcash.jpeg';
import nwLogo from '../assets/nw.png';
import factCheckLogo from '../assets/fact_check.svg';
import thumbsUpOutlineLogo from '../assets/thumbs-up-regular.svg';
import thumbsUpSolidLogo from '../assets/thumbs-up-solid.svg';
import thumbsDownOutlineLogo from '../assets/thumbs-down-regular.svg';
import thumbsDownSolidLogo from '../assets/thumbs-down-solid.svg';
import commentOutlineLogo from '../assets/comment-dots-regular.svg';
import commentSolidLogo from '../assets/comment-dots-solid.svg';
import xmarkLogo from '../assets/xmark-solid.svg';
import botLogo from '../assets/chatbot-logo.gif';
import botLogoAIA from '../assets/chatbot-logo-aia.gif';
import AIALogo from '../assets/aia-logo.png';
import AIAAvatar from '../assets/avatar.svg';
import SETTINGS_ICON from '#assets/settings.svg';
import CHECK_ICON from '#assets/check.svg';

import Linkify from 'linkify-react';
import _ from 'lodash';
import '../styles/message.scss';

import { useParams } from 'react-router-dom';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute('rel', 'noopener noreferrer');
  }
});
const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

function Message({
  role,
  contentText,
  theme = 'AIA Demo',
  status = 'done',
  useBubble = false,
  handleClick = () => { },
  contentResources = { type: null, data: [] },
  metadata = {},
  messageId,
  sessionId,
  feedbackAction = false,
  feedbackContent = "",
  isError = false,
  verificationResult = "",
  handleProductCategory = () => { },
  handleProductComparison = () => { },
  handleFactCheck = () => { },
  showFactCheckBtn,
  chatMessagesRef,
  message,
  index,
}, ref) {

  const dialog = useRef(null);
  const [sourceFile, setSourceFile] = useState('');
  const [sourceFileUrl, setSourceFileUrl] = useState('');
  const [resourceIndex, setResourceIndex] = useState(0);
  const [isFactCheckBtnClicked, setIsFactCheckBtnClicked] = useState(false);

  const { company = 'aim' } = useParams();
  const themes = {
    backGroundColorMappingBubble: {
      system: {
        base: { background: 'rgb(255, 255, 255)', border: `4px var(--company-identity-color) solid`, fontStyle: 'italic' },
      },
      user: {
        base: { background: `var(--company-identity-color)`, color: 'white', fontWeight: 500 },
      },
      assistant: {
        base: { background: 'rgb(255, 255, 255)' },
        startNewTopic: { background: 'rgb(255, 255, 255)', border: `4px var(--company-identity-color) solid`, fontStyle: 'italic' }
      },
      assistantDimmed: {
        base: { background: 'rgb(220, 220, 220)' },
        startNewTopic: { background: 'rgb(255, 255, 255)', border: `4px var(--company-identity-color) solid`, fontStyle: 'italic' }
      }
    },
    logo: company === "aia" ? AIAAvatar : botLogo,
    // logo: botLogo,
  };

  const backGroundColorMappingLine = {
    'system': 'rgb(50,63,93)',
    'user': 'rgb(52,56,65)',
    'assistant': 'rgb(60,70,84)'
  };

  const lineStyles = {
    display: 'flex',
    flexDirection: 'row',
    margin: '0px',
    padding: '10px',
    justifyContent: 'center',
    background: backGroundColorMappingLine[role]
  };

  const bubbleStyles = {
    display: 'flex',
    flexDirection: 'row',
    margin: '0px',
    padding: '10px',
    justifyContent: (role === 'user') ? 'flex-end' : 'flex-start'
  };

  const clickedLike = feedbackAction === "like" ? true : false;
  const clickedDislike = feedbackAction === "dislike" ? true : false;

  const feedbackContentDialog = useRef(null);
  const [currentFeedbackContent, setCurrentFeedbackContent] = useState(feedbackContent);

  const [hasClickedLike, setHasClickedLike] = useState(clickedLike);
  const [hasClickedDislike, setHasClickedDislike] = useState(clickedDislike);
  const [hasSentFeedbackContent, setHasSentFeedbackContent] = useState(feedbackContent);

  const handleSendFeedbackContent = useCallback(async () => {
    setHasSentFeedbackContent(true);
    await sendFeedback(sessionId, messageId, "", currentFeedbackContent);
    feedbackContentDialog.current.close();
  }, [setHasSentFeedbackContent, currentFeedbackContent, feedbackContentDialog]);

  const handleLikeDislike = useCallback(async (action) => {
    if (action === "like") setHasClickedLike(true);
    if (action === "dislike") setHasClickedDislike(true);
    sendFeedback(sessionId, messageId, action, "");
  }, [setHasClickedLike, setHasClickedDislike]);

  const botIcon = useCallback(node => {
    if (node !== null) {
      const firstBotIcon = document.getElementsByClassName("rotating-bot-icon")[0];
      if (firstBotIcon?.getAnimations()?.length > 0 && firstBotIcon?.getAnimations()[0].startTime) {
        node.getAnimations()[0].startTime = firstBotIcon.getAnimations()[0].startTime;
      }
    }
  }, []);

  // map paragraphs with filename, url and isRelative => get unique objects
  const getFileUrls = (contentResource) => {
    return _.uniqWith(
      _.map(contentResource.data, paragraph =>
        _.pick(paragraph, ['filename', 'url', 'isRelative'])
      ),
      _.isEqual);
  };
  function getBotIcon() {
    if (role === "user") {
      return null;
    }

    if (company === "aia") {
      return (
        <div className="aia-bot-logo-container">
          <img
            alt="[Logo]"
            className="bot-logo"
            src={ themes.logo }
            ref={ botIcon }
          />
        </div>
      );
      ;
    }

    return (
      <img
        alt="[Logo]"
        className="bot-logo rotating-bot-icon"
        src={ themes.logo }
        ref={ botIcon }
      />
    );
  }

  const resourceMapping = {
    referenceParagraphs: {
      title: "References:",
      urlMapping: (fileUrls, index) =>
        _.map(fileUrls, ({ filename, url, isRelative }, i) =>
          <div className='detail-box' key={ i } onClick={ () => {
            dialog.current.showModal();
            setSourceFile(filename);
            setSourceFileUrl(isRelative ? `${ process.env.REACT_APP_BACKEND_HOST }${ url }` : url);
            setResourceIndex(index);
          } }>{ filename }</div>
        )
    },
    productCategories: {
      title: "Product Categories:",
      urlMapping: (productCategories, index) =>
        _.map(productCategories, ({ productCategory, products }, i) =>
          <div
            key={ i }
            className='detail-box'
            style={ (productCategory.haveInfo) ? {} : { "pointerEvents": "none", "color": "lightgray" } }
            onClick={ () => { handleProductCategory(productCategory.name); } }>{ productCategory.name }
          </div>
        )
    },
    documentLinks: {
      title: "Documents:",
      urlMapping: (fileUrls) =>
        _.map(fileUrls, ({ filename, url, isRelative }, i) =>
          <div className='detail-box' key={ i } >
            <a target="_blank" rel="noreferrer" href={ isRelative ? `${ process.env.REACT_APP_BACKEND_HOST }${ url }` : url }>{ filename }</a>
          </div>
        )
    },
    comparedProducts: {
      title: "Compared products:",
      urlMapping: (products, index) => {

        let productNames = products.map(product => product.name);
        let productCodes = products.map(product => product.code);
        return (
          <div
            key={ "comparedProducts" }
            className='detail-box'
            onClick={ () => { handleProductComparison(productCodes); } }>{ productNames.join(" & ") }
          </div>
        );
      }
    },
    compareProductFromRetriever: {
      title: "Compared products:",
      urlMapping: (products, index) => {

        let productNames = products.map(product => product.name);
        let productCodes = products.map(product => product.code);
        return (
          <div
            key={ "comparedProducts" }
            className='detail-box'
            onClick={ () => { handleProductComparison(productCodes); } }>{ productNames.join(" & ") }
          </div>
        );
      }
    },
    uniquenessProduct: {
      title: "Compared products:",
      urlMapping: (products, index) => {

        let productNames = products.map(product => product.name);
        let productCodes = products.map(product => product.code);
        return (
          <div
            key={ "comparedProducts" }
            className='detail-box'
            onClick={ () => { handleProductComparison(productCodes); } }>{ productNames.join(" & ") }
          </div>
        );
      }
    },
  };

  const preprocessCitation = (text) => {
    const regex = /\[\^(\d+)\^#page=(\d+)\]/g;
    const isCitationExist = regex.test(text);
    if (!isCitationExist) {
      return text;
    }
    let i = 1;
    const parsedText = text.replaceAll(regex, (match, docId, page) => {
      const docIdMapping = {
        1: 'A_Life_Wealth_Venture_Brochure_1st_v2.pdf',
        2: 'A-Life_MediFlex_Brochure_Final_v2.pdf',
        3: 'A-Life_MediFlex-i_Brochure_04JUL24.pdf',
        4: 'A-Plus_Health_2_Brochure_Final_v2.pdf',
        5: 'ALPWT Slides_c010a8fe-6b7a-4b35-8895-d09a34f9b200_.pdf',
        6: 'InSights Training Slides Pilot Final_KL.pdf',
        7: 'Q3 2024 Unbeatable! Unstoppable! Unrivalled! Challenge 20240701 - Personal Sales - NEW.pdf',
      };
      const url = `${ BACKEND_HOST }/documents/${ docIdMapping[docId] }#page=${ page }`;
      const html = `<a class="cite" href="${ url }"><sup>${ i }</sup></a>`;
      i += 1;
      return html;
    });
    return parsedText;
  };

  const preprocessContentText = (text) => {
    let parsedText = text;
    parsedText = preprocessCitation(parsedText);
    return parsedText;
  };

  const bubbleStyle = () => {
    if (message.content.type === "message") {
      if (role === "assistant")
        return 'chat-bubble chat-bubble-assistant';
      if (role === "user")
        return 'chat-bubble chat-bubble-user';
    }
    return 'chat-bubble--tool-call chat-bubble-assistant';
  };

  return (
    <div
      style={ useBubble ? bubbleStyles : lineStyles }
      ref={ (element) => chatMessagesRef.current[index] = element }
    >
      { getBotIcon() }
      <div className={ bubbleStyle() }
        style={
          verificationResult === "no" && role === 'assistant' ? themes.backGroundColorMappingBubble['assistantDimmed']['base'] :
            themes.backGroundColorMappingBubble[role][metadata.customAction] || themes.backGroundColorMappingBubble[role]["base"]
        }
      >
        {
          role === 'assistant' && metadata?.contextFilter?.productCategories && metadata?.contextFilter?.productCategories.length > 0 &&
          <div className='context-tag-container'>
            { _(metadata?.contextFilter?.productCategories).map(category =>
              <div>{ category }</div>
            ).value() }
          </div>
        }
        { status === 'streaming' && contentText &&
          <Markdown
            rehypePlugins={ [rehypeRaw] }
            remarkPlugins={ [remarkGfm] }
            className='message-content'
          >
            { DOMPurify.sanitize(preprocessContentText(contentText), {}) }
          </Markdown>
          // <div> { contentText } </div> // links are not clickable when streaming is still in progress
        }
        { status === 'waiting' &&
          <div style={ { width: '29px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
            <div className="dot-flashing" ></div>
          </div>
        }
        { status === 'done' && contentText &&
          <div className='chat-bubble-container'>
            {
              message.content.type === "tool_call_start" &&
              <div className='bubble--tool_call'>
                <div className='bubble__content'> Invoking action</div>
                <div className='tool-call__footer'>
                  <img src={ SETTINGS_ICON } alt='Invoking action' className='tool-call__icon tool-call__icon--rotating' />
                  { contentText }
                </div>
              </div>
            }
            {
              message.content.type === "tool_call_end" &&
              <div className='bubble--tool_call'>
                <div className='bubble__content'>Action completed</div>
                <div className='tool-call__footer'>
                  <img src={ CHECK_ICON } alt='Action completed' className='tool-call__icon' />
                  { contentText }
                </div>
              </div>
            }
            {
              message.content.type === "message" && <>
                <Markdown
                  rehypePlugins={ [rehypeRaw] }
                  remarkPlugins={ [remarkGfm] }
                  className='message-content'
                >
                  { DOMPurify.sanitize(preprocessContentText(contentText), {}) }
                </Markdown>
                { role === 'assistant' && !isError && !(metadata?.allowFeedback === false) &&
                  <div className="message-icons">
                    { showFactCheckBtn && !isFactCheckBtnClicked && (
                      <button
                        className="left-radius"
                        onClick={ () => {
                          handleFactCheck(messageId);
                          setIsFactCheckBtnClicked(true);
                        } }
                      >
                        <img
                          className="fact-check-logo"
                          alt="fact check"
                          src={ factCheckLogo }
                        />
                      </button>
                    ) }
                    <button className="left-radius" disabled={ hasClickedLike || hasClickedDislike } onClick={ () => handleLikeDislike("like") }>
                      <img alt='like' src={ hasClickedLike ? thumbsUpSolidLogo : thumbsUpOutlineLogo } />
                    </button>
                    <button className={ `${ !hasSentFeedbackContent ? "right-radius" : "" }` } disabled={ hasClickedLike || hasClickedDislike } onClick={ () => handleLikeDislike("dislike") }>
                      <img alt='dislike' src={ hasClickedDislike ? thumbsDownSolidLogo : thumbsDownOutlineLogo } />
                    </button>
                    <button className={ `${ hasSentFeedbackContent ? "right-radius" : "" }` } disabled={ !(hasClickedLike || hasClickedDislike) || hasSentFeedbackContent } onClick={ () => feedbackContentDialog.current.showModal() }>
                      <img alt='comment' src={ hasSentFeedbackContent ? commentSolidLogo : commentOutlineLogo } />
                    </button>
                  </div>
                }
              </>
            }

            <dialog className='feedback-content' ref={ feedbackContentDialog }>
              <div className='feedback-container'>
                <textarea
                  maxLength="1000"
                  className='feedback-content-input'
                  placeholder='Enter your feedback...'
                  onInput={ e => setCurrentFeedbackContent(e.target.value) }
                  value={ currentFeedbackContent } />
              </div>
              <div className='button-container'>
                <button className='cancel' onClick={ (e) => { feedbackContentDialog.current.close(); setCurrentFeedbackContent(""); } }>Cancel</button>
                <button className='send' onClick={ (e) => { handleSendFeedbackContent(); } }>Send</button>
              </div>
            </dialog>
          </div>
        }
        { (contentResources?.length ?? 0) !== 0 &&
          _.map(contentResources, (contentResource, i) => {
            const resourceType = contentResource.type;
            let resourceComponent;
            switch (resourceType) {
              case 'referenceParagraphs':
              case 'documentLinks':
                const fileUrls = getFileUrls(contentResource);
                resourceComponent = (
                  <div key={ i }>
                    { (fileUrls?.length ?? 0) !== 0 &&
                      <div className='supplement-info'>
                        <div className='detail-message'>{ resourceMapping[resourceType]["title"] }</div>
                        <div className='source-detail-container'>
                          { resourceMapping[resourceType]["urlMapping"](fileUrls, i) }
                        </div>
                      </div>
                    }
                  </div>
                );
                break;
              case 'productCategories':
              case 'comparedProducts':
              case 'uniquenessProduct':
              case 'compareProductFromRetriever':
                const data = contentResource.data;
                resourceComponent = (
                  <div key={ i }>
                    { (data?.length ?? 0) !== 0 &&
                      <div className='supplement-info'>
                        <div className='detail-message'>{ resourceMapping[resourceType]["title"] }</div>
                        <div className='source-detail-container'>
                          { resourceMapping[resourceType]["urlMapping"](data, i) }
                        </div>
                      </div>
                    }
                  </div>
                );
                break;
              default:
                console.log('content resources type out of scope');
                break;
            }
            return resourceComponent;
          })
        }
      </div>
      <dialog className='paragraph-source' ref={ dialog }>
        <div className='option-box-header'>
          <label>Answer Sources</label>
        </div>
        <button className='xmark-button' onClick={ () => {
          dialog.current.close();
          setSourceFile('');
        } }>
          <img alt='comment' src={ xmarkLogo } />
        </button>
        <div className='source-text'>
          { (contentResources?.length ?? 0) !== 0 &&
            _.map(_.groupBy(contentResources[resourceIndex].data, 'filename')[sourceFile], ({ paragraph }, i) => {
              return <div className='source-paragraph' key={ i }>{ parse(DOMPurify.sanitize(paragraph, { USE_PROFILES: { html: true }, })) }</div>;
            })
          }
        </div>
        <div className='source-reference'>
          File reference: <a target="_blank" rel="noreferrer" href={ sourceFileUrl }>{ sourceFile }</a>
        </div>
      </dialog>
    </div>
  );
}

export default forwardRef(Message);