import { useState } from 'react';
import "./portalBody.scss";

const options = [
  'Individual Life',
  'General Insurance',
  'Personal Life Insurance',
  'Takaful',
  'Corporate Solution'
];

const REDIRECT_TNC_URL = "https://www.alpp.aia.com.my/LifePlannerPortalExisting/login/AgreementDefault.aspx";

const PortalBody = () => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const onChangeSelect = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <div className='portal-body'>
      <h4 className='portal-body__welcome'>WELCOME TO AIA LIFE PLANNER PORTAL</h4>
      <form className='portal-body__form'>
        <input className='portal-body__input' placeholder='USER ID' type="text" name='username' />
        <input className='portal-body__input' placeholder='********' type='password' name='password' />
        <select className='portal-body__input' value={ selectedOption } onChange={ onChangeSelect } name='category'>
          {
            options.map((option, index) => <option value={ option } key={ index }>{ option }</option>)
          }
        </select>
        <p>
          <input type='checkbox' />
          I have read and agreed to the
          <br />
          <a
            className='portal-body__link'
            rel='noreferrer noopener'
            target='_blank'
            href={ REDIRECT_TNC_URL }>
            Terms and condition
          </a>
        </p>

        <button className='portal-body__button' type='submit'>
          LOGIN
        </button>
      </form>
      <div className='portal-body__alternatives'>
        <a className='alternatives__option' href="https://www.alpp.aia.com.my/LifePlannerPortalExisting/ResetUserIdPwd.aspx?T=v%2fHBKP7khO3JNy0X4QbnSQ%3d%3d&usrid=m0qIPzIEdWTzdl0sROG%2bGw%3d%3d">FORGOTTEN YOUR PASSWORD?</a>
        <a className='alternatives__option' href="https://www.alpp.aia.com.my/LifePlannerPortalExisting/Agency/OnlineRegistrationForm.aspx?Portal=kDpLve0vHlO%2f2BGslqbB6A%3d%3d">USER ID LOCKED?</a>
        <a className='alternatives__option' href="https://www.alpp.aia.com.my/LifePlannerPortalExisting/Agency/OnlineRegistrationForm.aspx?Portal=kDpLve0vHlO%2f2BGslqbB6A%3d%3d">REGISTER NOW!</a>
      </div>
      <p className='portal-body__disclaimer'>
        You are entering AIA Bhd.'s secured services section for our Agency force. You need to have a valid User ID and password in order to gain access to the services.
      </p>
    </div>
  );
};

export default PortalBody;