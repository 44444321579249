import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';

// Hooks
import { useChatHistory } from "#hooks/chatHistory";

// adapters
import {
  getUserChatHistory,
  makeQueryV2,
  getProductMapping,
  verifyRespond,
} from '../../adapters/restAPIServices';

import TitleBar from './TitleBar';
import ChatHistory from './ChatHistory';
import AiNavigator from './AiNavigator';
import MessageBar from './MessageBar';
import Export from './Export';

// global user state
import UserContext from '../../contexts/user/user';

// styles
import '../../styles/chatroom.scss';

const Chatroom = ({ setComparedProducts = () => { } }) => {
  const { company = 'aim' } = useParams();

  const [productCategory, setProductCategory] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productContext, setProductContext] = useState(null);
  const [productMapping, setProductMapping] = useState([]);
  const [isCleanHistory, setIsCleanHistory] = useState(false);
  const [showCleanHistoryBtn, setShowCleanHistoryBtn] = useState(false);

  const { userId, role, logout, sessionId, name } = useContext(UserContext);

  const chatBottom = useRef(null);
  const dialog = useRef(null);
  const scrollEnabled = useRef(true);
  const container = useRef(null);
  const productCategorySelect = useRef(null);

  const { chatHistory, dispatchChatHistory } = useChatHistory();

  // useEffect(() => {
  //   if (userId) {
  //     getUserChatHistory()
  //       .then((data) => {
  //         dispatchChatHistory({
  //           type: 'fetch',
  //           company,
  //           payload: { conversations: data.chatConversations, productMapping },
  //         });
  //         scrollToBottom();
  //       })
  //       .catch(logout);
  //   }
  //   getProductMapping(company).then((data) => {
  //     setProductMapping(data.response);
  //   });
  //   const iframeReady = (event) => {
  //     let message = event.data;
  //     if (message.scroll === true) {
  //       scrollEnabled.current = true;
  //       scrollToBottom('instant');
  //     }
  //     if (message.scroll === false) {
  //       scrollEnabled.current = false;
  //     }
  //   };
  //   window.addEventListener('message', iframeReady);
  //   window.parent.postMessage({ ready: true }, '*');
  //   return () => {
  //     window.removeEventListener('message', iframeReady);
  //   };
  // }, [userId, logout]);


  const scrollToBottom = (behavior = 'smooth') => {
    if (scrollEnabled.current)
      setTimeout(() => chatBottom.current?.scrollIntoView({ behavior }), 300);
  };

  const handleAsk = useCallback(async (query) => {

  },
    []
  );

  const getOnMessageFn = (streamId, controller) => (msg) => {
    const data = JSON.parse(msg.data);
    // Throw error if the chat response is an error
    if (msg.event === 'StreamEventError') {
      throw new Error(data);
    }
    // Close the stream if the conversation is done
    if (data.done) {
      controller.abort();
      return;
    }
    // Update the final response if the chat response is complete
    if (data.complete) {
      const { response, standaloneQuestion } = data;
      dispatchChatHistory({
        type: 'update',
        filter: (chat) => chat.streamId === streamId,
        payload: {
          ...response,
          status: 'done',
          metadata: {
            ...response.metadata,
            standaloneQuestion: standaloneQuestion,
          },
        },
      });
      return;
    }
    // Update the stream response
    const { messageId, userId, sessionId, delta = {} } = data;
    let { role = '', content = '' } = delta;
    if (content === null) {
      content = '';
    }
    if (!role && !content) {
      return;
    }
    dispatchChatHistory({
      type: 'stream',
      payload: {
        messageId,
        streamId,
        status: 'streaming',
        content: { text: content },
      },
    });
  };

  const getOnErrorFn = (streamId) => (err) => {
    console.error(err);
    const status = err?.status;
    const errorResponse = {
      role: 'assistant',
      content: {
        text:
          status == 429
            ? 'Maximum amount of requests reached. Please try again in a few minutes.'
            : 'An error has occurred. Please try again later.',
      },
      isError: true,
    };
    dispatchChatHistory({
      type: 'update',
      filter: (chat) => chat.streamId === streamId,
      payload: errorResponse,
    });
    throw err;
  };

  const handleCleanContext = useCallback(async () => {
    setProductContext(null);
    setProductCategory(null);
    setIsCleanHistory(true);
    setShowCleanHistoryBtn(false);
    const content = {
      text: 'I am ready for a new question, what can I do for you?',
    };
    dispatchChatHistory({
      type: 'append',
      company,
      payload: {
        role: 'assistant',
        content,
        metadata: { allowFeedback: false },
      },
    });
  }, []);

  const handleFactCheck = async (messageId) => {
    const respondChatIdx = chatHistory.findIndex(
      (chat) => chat.messageId === messageId
    );
    const question = chatHistory[respondChatIdx]?.metadata?.standaloneQuestion;
    if (!question) {
      return;
    }
    // setIsInQuery(true);
    scrollToBottom();
    const {
      success,
      responses,
      standaloneQuestion,
      useOriginalQuery,
      errorResponse,
    } = await verifyRespond(messageId, question, company);

    if (success) {
      console.log(`standaloneQuestion: ${ standaloneQuestion }`);
      const originalResponse = chatHistory[respondChatIdx];
      for (const response of responses) {
        originalResponse.verificationResult = response.verificationResult;
        dispatchChatHistory({ type: 'append', company, payload: response });
      }
    } else {
      dispatchChatHistory({ type: 'append', company, payload: errorResponse });
    }

    // setIsInQuery(false);
  };
  return (
    <div className="chatroom-container" ref={ container }>
      <TitleBar
        name={ name }
        role={ role }
        dialog={ dialog }
        logout={ logout }
      />
      <ChatHistory
        scrollToBottom={ scrollToBottom }
        company={ company }
        handleAsk={ handleAsk }
        setProductCategory={ setProductCategory }
        productCategorySelect={ productCategorySelect }
        setComparedProducts={ setComparedProducts }
        handleFactCheck={ handleFactCheck }
        sessionId={ sessionId }
        chatBottom={ chatBottom }
      />
      {/* <AiNavigator
        productCategory={ productCategory }
        productMapping={ productMapping }
        productContext={ productContext }
        setProductCategory={ setProductCategory }
        setProductName={ setProductName }
        productName={ productName }
        showCleanHistoryBtn={ showCleanHistoryBtn }
        handleCleanContext={ handleCleanContext }
      /> */}
      <MessageBar
        productCategory={ productCategory }
        handleAsk={ handleAsk }
      />
      <Export dialog={ dialog } />
    </div>
  );
};

export default Chatroom;
