import React, { useState } from "react";
import Select, { components } from "react-select";
import { exportCompareTable } from "../../adapters/restAPIServices";

import addIcon from "../../assets/add-aia-red.svg";
import downloadIcon from "../../assets/download-white.svg";

import canLogo from "../../assets/product-comparison-can.svg";
import cannotLogo from "../../assets/product-comparison-cannot.svg";
import aiaLogo from "../../assets/aia-logo.png";

import ExportButton from "./ExportButton";

import "./CompareToolbar.scss";

function preprocessValue(value) {
  if (value === "//can//") {
    return {
      value: "//can//",
      src: canLogo,
    };
  }
  if (value === "//cannot//") {
    return {
      value: "//cannot//",
      src: cannotLogo,
    };
  }
  return value;
}

async function downloadPDF(productFeatureData) {
  if (!productFeatureData) return;
  if (!Array.isArray(productFeatureData)) return;
  if (productFeatureData.length === 0) return;
  if (productFeatureData.some((row) => !Array.isArray(row))) return;

  const processedProductFeatureData = productFeatureData.map((row) =>
    row.map((value) => preprocessValue(value))
  );

  const pdfBlob = await exportCompareTable(
    processedProductFeatureData,
    aiaLogo
  );
  const url = window.URL.createObjectURL(pdfBlob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "compare-table.pdf";
  a.click();
}

const CompareToolbar = ({
  productOptions = [],
  selectedProductOptions = [null],
  selectProduct,
  deleteProduct,
  addProduct,
  maxProduct = 3,
  productFeatureData,
}) => {
  const [exportButtonStatus, setExportButtonStatus] = useState("ready");

  async function onClickExportBtn() {
    setExportButtonStatus("loading");
    await downloadPDF(productFeatureData);
    setExportButtonStatus("ready");
  }

  const productStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: "8px",
      border: `1px solid var(--company-identity-color)`,
      paddingTop: "11px",
    }),
    option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
      ...baseStyles,
      // color: isDisabled ? "grey" : data.value ? "black" : "#009188"
    }),
  };

  const Control = ({ children, ...props }) => (
    <components.Control { ...props }>
      <div
        style={ {
          fontSize: "11px",
          fontWeight: "600",
          color: "var(--company-identity-color)",
          position: "absolute",
          top: "-5px",
          padding: "8px",
        } }
      >
        Product { props.selectProps.selectProps.productIndex + 1 }:
      </div>
      { children }
    </components.Control>
  );

  return (
    <div className='compare-toolbar'>
      <div className="compare-toolbar-action-row">
        <div className="product-select-group">

          <div className="toolbar-header">Compare Product(s)</div>
        </div>

        <div className="btn-group">
          <button
            disabled={ selectedProductOptions.length >= maxProduct }
            className="add-product-btn button"
            onClick={ () => addProduct() }
          >
            <img className="add-icon" src={ addIcon } />
          </button>
          <ExportButton
            status={ exportButtonStatus }
            onClickExportBtn={ onClickExportBtn }
          />
        </div>
      </div>
      <div className="product-select-group">

        { selectedProductOptions.map((selectedOption, idx) => (
          <div key={ idx } className="product-select-container">
            <Select
              selectProps={ { productIndex: idx } }
              placeholder="Select..."
              value={ selectedOption }
              onChange={ (e) => selectProduct(idx, e.value) }
              menuPlacement="bottom"
              styles={ productStyles }
              options={ productOptions }
              components={ { Control } }
            />
            <button
              className="delete-product-btn"
              onClick={ () => deleteProduct(idx) }
            >
              x
            </button>
          </div>
        )) }
      </div>

    </div>

  );
};

export default CompareToolbar;
