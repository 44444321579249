
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import { useState, useRef, useEffect } from 'react';
import { useChatbot } from '#hooks/chatbot';

import CROSS_IMAGE from '#assets/cross.svg';

import "./floatingChatbot.scss";
import Menu from '#pages/menu';

const FloatingChatBot = () => {
  const chatbotRef = useRef(null);
  const { chatbot, clearNotification, onToggleChat } = useChatbot();
  const { notificationMessage } = chatbot;

  const [temporaryNotification, setTemporaryNotification] = useState(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
        if (chatbot.isOpen) onToggleChat(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [chatbotRef, chatbot.isOpen, onToggleChat]);

  useEffect(() => {
    if (notificationMessage) setTemporaryNotification(notificationMessage);
  }, [notificationMessage, clearNotification]);

  const onClickNotification = () => {
    onToggleChat(true);
    clearNotification();
  };
  const onClickCloseNotification = (e) => {
    e.stopPropagation();
    clearNotification();
  };
  const onClickChatbot = () => {
    clearNotification();
    onToggleChat(true);
  };

  return (
    <div className="floating-chat-bot" >
      {
        <div className={ `floating-chat-bot__chat-container ${ !chatbot.isOpen && 'floating-chat-bot__chat-container--hide' }` }>
          <Menu ref={ chatbotRef } />
        </div>
      }
      <div
        className={ `floating-chat-bot__message ${ notificationMessage ? 'floating-chat-bot__message--show' : 'floating-chat-bot__message--hide' }` }
        onClick={ onClickNotification }
      >
        <div className='floating-chat-bot__close' onClick={ onClickCloseNotification }>
          <img src={ CROSS_IMAGE } alt='Close Notification' />
        </div>
        <div className='floating-chat-bot__message-header'>
          Notification
        </div>
        <p className='floating-chat-bot__message-paragraph'>
          { parse(DOMPurify.sanitize(temporaryNotification)) }
        </p>
      </div>

      <div className='floating-chat-bot__avatar' onClick={ onClickChatbot } />
    </div >
  );
};

export default FloatingChatBot;