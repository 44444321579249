import AIA_BANNER from "#assets/logo-desktop.png";
import "#components/notFound/notFound.scss";
import useTheme from '#hooks/useTheme';

const NotFound = () => {
  useTheme('aia');
  return (<div className='notFound'>
    <div className='notFound__banner'>
      <img src={ AIA_BANNER } alt='banner' />
    </div>
    <main className='notFound__main'>
      <span>404 | NOT FOUND</span>

    </main>

  </div>);
};

export default NotFound;;