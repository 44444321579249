import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import theme from '#config/theme';

const useTheme = (defaultCompany) => {
  const { company = defaultCompany ?? 'aim' } = useParams();

  useLayoutEffect(() => {
    document.documentElement.style.setProperty('--background-color', (theme[company]?.backgroundColor || theme['aim'].backgroundColor));
    document.documentElement.style.setProperty('--company-identity-color', (theme[company]?.companyIdentityColor || theme['aim'].companyIdentityColor));
    document.documentElement.style.setProperty('--svg-filter', (theme[company]?.filter || theme['aim'].filter));
  }, [company]);
};
export default useTheme;