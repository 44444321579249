import React from "react";
import filterLogo from "../../assets/filter.svg";

import "./FilterBox.scss";

const FilterBox = ({ filterValue, setFilterValue }) => {
  return (
    <div className="filter-box">
      <img className="filter-icon" src={filterLogo} />
      <input
        className="filter-value-field"
        value={filterValue}
        onChange={(event) => setFilterValue(event.target.value)}
      ></input>
    </div>
  );
};

export default FilterBox;
