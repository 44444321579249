import { createContext, useReducer } from 'react';
import { chatbotInitialState, chatbotReducer } from '#reducers/chatbot';

const ChatbotContext = createContext(chatbotInitialState);


const ChatbotProvider = ({ children }) => {

  const [chatbot, dispatchChatbot] = useReducer(chatbotReducer, chatbotInitialState);
  return (
    <ChatbotContext.Provider value={ { chatbot, dispatchChatbot } }>
      { children }
    </ChatbotContext.Provider>);
};

export {
  ChatbotContext,
  ChatbotProvider,
};