import { useState } from 'react';
import "./portalFooter.scss";



const PortalFooter = () => {
  return (
    <div className='portal-footer'>
      <span className='portal-footer__title'>IMPORTANT INFORMATION</span>
      <p className='portal-footer__description'>
        Best viewed with Internet Explorer Version 10 and above; latest non-beta versions of Safari, Chrome & Firefox. You may experience difficulty using the site if you are using other browsers.
      </p>
      <a className='portal-footer__link' href='https://www.alpp.aia.com.my/LifePlannerPortalExisting/AIADisclaimer/disclaim99.html' rel="noreferrer noopener" target='__blank'>Terms of Use</a>
      <a className='portal-footer__link' href="https://www.aia.com.my/en/index/privacy-statement.html" rel="noreferrer noopener" target='__blank'>Privacy Statement</a>
      <a className='portal-footer__link' href="https://www.aia.com.my/en/about-aia/customer-charter.html" rel="noreferrer noopener" target='__blank'>Customer Center</a>
      <p className='portal-footer__copyright'>
        Copyright © 2024, AIA Bhd.(790895-D)
      </p>
    </div>
  );
};

export default PortalFooter;;