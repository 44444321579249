const SpeechRecognition = typeof window !== 'undefined' && (
  window.SpeechRecognition ||
  window.webkitSpeechRecognition ||
  window.mozSpeechRecognition ||
  window.msSpeechRecognition ||
  window.oSpeechRecognition
)
// const SpeechGrammarList = typeof window !== 'undefined' && (
//   window.SpeechGrammarList ||
//   window.webkitSpeechGrammarList ||
//   window.mozSpeechGrammarList ||
//   window.msSpeechGrammarList ||
//   window.oSpeechGrammarList
// )
// const SpeechRecognitionEvent = typeof window !== 'undefined' && (
//   window.SpeechRecognitionEvent ||
//   window.webkitSpeechRecognitionEvent ||
//   window.mozSpeechRecognitionEvent ||
//   window.msSpeechRecognitionEvent ||
//   window.oSpeechRecognitionEvent
// )
console.log(`Recognition ready`)
const recognition = new SpeechRecognition();
// const speechRecognitionList = new SpeechGrammarList();
// speechRecognitionList.addFromString(grammar, 1);
// recognition.grammars = speechRecognitionList;
recognition.continuous = false;
// recognition.lang = "en-US";
recognition.lang = "yue-Hant-HK";
recognition.interimResults = true;
recognition.maxAlternatives = 1;
export function recognizeOnce(resultCB, stopCB) {
  console.log(`Recognition starts`)
  recognition.start();
  recognition.onresult = (event) => {
    const {transcript, confidence} = event.results[0][0]
    console.log(`Recognition result received: ${transcript}.`)
    console.log(`Recognition confidence: ${confidence}`);
    resultCB({transcript, confidence})
  };
  recognition.onspeechend = () => {
    recognition.stop();
    stopCB?.()
  };
}