import React, { useState, forwardRef } from 'react';


// Contexts
import { ChatHistoryProvider } from '#contexts/chatHistory';

// Components
import Chatroom from '../components/Chatroom/Chatroom';
import Compare from '../components/compare/Compare';
import Upload from '../components/upload';

// Configs
import { menuType } from '#config/config';

// styles
import '../styles/menu.scss';

const Menu = forwardRef((props, ref) => {
  const [section, setSection] = useState(menuType.Chatroom);
  const [selectedProductCodes, setSelectedProductCodes] = useState([]);

  function setComparedProducts(productCodes) {
    setSelectedProductCodes(productCodes);
    setSection(menuType.Compare);
  }

  const onBackChat = () => {
    setSection(menuType.Chatroom);
  };

  return (
    <div className="container" ref={ ref }>
      {
        section === menuType.Chatroom &&
        (<ChatHistoryProvider>
          <Chatroom setComparedProducts={ setComparedProducts } />
        </ChatHistoryProvider>)
      }

      {
        section === menuType.Upload && <div
          className={ 'section-container' }
        >
          <Upload />
        </div>
      }
      {
        section === menuType.Compare && <div
          className={
            'section-container'
          }
        >
          <Compare
            selectedProductCodes={ selectedProductCodes }
            setSelectedProductCodes={ setSelectedProductCodes }
            onBackClick={ onBackChat }
          />
        </div>
      }

    </div>
  );

});

export default Menu;
