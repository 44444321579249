import React from 'react';

import userLogo from '../../assets/user-solid.svg';
import './TitleBar.scss';
import newChatLogo from '../../assets/new-chat.svg';
import { chatbotMode } from '#config/config';

// Hooks
import { useChatbot } from '#hooks/chatbot';
import { useChatHistory } from '#hooks/chatHistory';

const TitleBar = ({ name, role, dialog, logout, mode, handleSetMode }) => {
  const { chatbot, toggleOnlyMalayProducts, initializeChatbot } = useChatbot();
  const { chatHistory } = useChatHistory();
  const isDisabled = chatHistory.status === chatbotMode.Streaming;

  const onClickReset = () => {
    if (isDisabled) return;
    initializeChatbot(true);
  };

  return (
    <div className="title-bar">
      <div className="title-options-bar">
        <div className="title-left-group">
          { name ? (
            <div>
              <img className="user-logo" alt="user" src={ userLogo }></img>
              { name }
            </div>
          ) : (
            <div>Welcome back!</div>
          ) }
          {/* <input
            type="checkbox"
            label="Malaysia Products Only"
            checked={chatbot.onlyMalaysiaProducts}
            onChange={() => toggleOnlyMalayProducts()}
          ></input>
          <span>Malaysia Products Only</span> */}
        </div>
        <div className="title-right-group">
          <button>
            <img
              className={ `user-logo ${ isDisabled && 'user-logo--disabled' }` }
              alt="user"
              src={ newChatLogo }
              onClick={ onClickReset }
            ></img>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
