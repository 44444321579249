import React, { useRef, useState } from 'react';
import { exportConversation } from '../../adapters/restAPIServices';
// import './Export.scss';

const Export = ({ dialog }) => {
  const currentDate = new Date().toISOString().slice(0, 10);
  const firstDayOfMonth = `${new Date().toISOString().slice(0, 7)}-01`;

  const [exportFrom, setExportFrom] = useState(firstDayOfMonth);
  const [exportTo, setExportTo] = useState(currentDate);

  const exportFromRef = useRef(null);
  const exportToRef = useRef(null);

  const handleExport = async () => {
    const blob = await exportConversation(exportFrom, exportTo);
    const fileURL = window.URL.createObjectURL(blob);
    const ele = document.createElement('a');
    ele.download = 'export.csv';
    ele.href = fileURL;
    ele.click();
    setTimeout(() => window.URL.revokeObjectURL(fileURL), 4e4);
  };

  return (
    <dialog className="export" ref={dialog}>
      <h3>Select a range</h3>
      <div>
        <label>From</label>
        <input
          className="selected"
          ref={exportFromRef}
          type="date"
          name="party"
          min="2023-01-01"
          max={currentDate}
          value={exportFrom}
          onChange={(e) => setExportFrom(e.target.value)}
        />
      </div>
      <div>
        <label>To</label>
        <input
          className="selected"
          ref={exportToRef}
          type="date"
          name="party"
          min="2023-01-01"
          max={currentDate}
          value={exportTo}
          onChange={(e) => setExportTo(e.target.value)}
        />
      </div>
      <div className="button-container">
        <button
          className="cancel"
          onClick={() => {
            dialog.current.close();
          }}
        >
          Cancel
        </button>
        <button
          className="download"
          onClick={() => {
            handleExport();
            dialog.current.close();
          }}
        >
          Download
        </button>
      </div>
    </dialog>
  );
};

export default Export;
