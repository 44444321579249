import React, { useState } from 'react';
import _ from 'lodash';

import sendLogo from '../../assets/send.svg';
import loadingLogo from '../../assets/loading-black.svg';

import './MessageBar.scss';

import { recognizeOnce } from '../../adapters/speechServices';



import micLogo from '../../assets/mic.png';

// Hooks
import { useChatHistory } from '#hooks/chatHistory';

// Config
import { chatbotMode } from '#config/config';

const MessageBar = ({ productCategory }) => {
  const [micReady, setMicReady] = useState(true);
  const [query, setQuery] = useState('');
  // current query in the question box, and user's navigation history (using up and down keys)
  const [queryIndex, setQueryIndex] = useState(-1);

  const { chatHistory, sendMessage } = useChatHistory();
  const isInQuery = chatHistory.status === chatbotMode.Streaming;

  const onSendMessage = () => {
    if (query === '' || isInQuery) return;
    //Send Message
    setQuery('');
    sendMessage(query);
  };


  const Dots = () => (
    <div
      style={ {
        width: '29px',
        height: '29px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      } }
    >
      <div className="dot-flashing"></div>
    </div>
  );

  const handleKeyUp = async (event) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      onSendMessage();

    }
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      if (event.key === 'ArrowUp') {
        const history = _(chatHistory.messages ?? [])
          .filter((e) => e.role === 'user')
          .value();
        let index = queryIndex !== -1 ? queryIndex - 1 : history.length - 1;
        index = Math.max(Math.min(index, history.length - 1), 0);
        setQuery(history[index]?.content?.text ?? '');
        setQueryIndex(index);
      }
      if (event.key === 'ArrowDown') {
        const history = _(chatHistory.messages ?? [])
          .filter((e) => e.role === 'user')
          .value();
        let index = queryIndex !== -1 ? queryIndex + 1 : history.length;
        index = Math.max(Math.min(index, history.length), 0);
        setQuery(history[index]?.content?.text ?? '');
        setQueryIndex(index);
      }
    }
  };
  return (
    <div className="query-bar">
      {/* <div className="new-topic-button" ref={newTopicButton}
      onClick={() => handleNewTopic()}
      onMouseEnter={() => handleExpandButton()}
      onMouseLeave={() => handleCollapseButton()}
      >
      <div className="new-topic-text">{newTopicText}</div>
    </div> */}
      <div className="query-box">
        <input
          placeholder={ 'Type your message...' }
          value={ query }
          maxLength={ 1000 }
          onKeyDown={ (event) => event.stopPropagation() }
          onChange={ (event) => setQuery(event.target.value) }
          onKeyUp={ (event) => handleKeyUp(event) }
        />
        <button
          onClick={ () => {
            setMicReady(false);
            recognizeOnce(
              ({ transcript }) => setQuery(query + transcript),
              () => setMicReady(true)
            );
          } }
        >
          { micReady ? <img alt="Enable mic" src={ micLogo } /> : <Dots /> }
        </button>
        {/* <button onClick={() => { getUserChatHistory().then(data => dispatchChatHistory({ type: 'fetch', payload: data.chatConversations })).catch(logout) }}>
        <img alt='Refresh' src={refreshLogo} />
      </button>
      <button onClick={() => { clearUserChatHistory(userId); dispatchChatHistory({ type: 'clear' }) }}>
        <img alt='Reset' src={trashLogo} />
      </button> */}

        <button
          onClick={ onSendMessage }
        >
          { isInQuery ? (
            <img alt="Loading" src={ loadingLogo } className='loading-icon' />
          ) : (
            <img alt="Send" src={ sendLogo } />
          ) }
        </button>
      </div>
    </div>
  );
};

export default MessageBar;
