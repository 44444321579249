import React from "react";
import downloadIcon from "../../assets/download-white.svg";
import loadingIcon from "../../assets/loading-white.svg";

import "./ExportButton.scss";

const ExportButton = ({ status = "ready", onClickExportBtn }) => {
  return (
    <button
      className={ `export-btn ${ status } button` }
      onClick={ () => status === "ready" && onClickExportBtn() }
      disabled={ status !== "ready" }
    >
      { status === "ready" ? (
        <img className="download-icon" src={ downloadIcon } />
      ) : (
        <img src={ loadingIcon } className="loading-icon"></img>
      ) }
    </button>
  );
};

export default ExportButton;
