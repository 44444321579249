import React, { useEffect, useLayoutEffect, useRef } from 'react';

// Hooks
import { useChatbot } from '#hooks/chatbot';
import { useChatHistory } from "#hooks/chatHistory";

import './ChatHistory.scss';

import Message from '../message';

const ChatHistory = ({
  company,
  handleAsk,
  setProductCategory,
  productCategorySelect,
  setComparedProducts,
  handleFactCheck,
  sessionId,
  chatBottom,
  scrollToBottom,
}) => {
  const chatHistoryContainer = useRef(null);
  const chatMessagesRef = useRef([]);

  const { chatbot } = useChatbot();

  // chat history
  const { chatHistory, fetchChatHistory } = useChatHistory();
  const { messages } = chatHistory;
  useLayoutEffect(() => {
    if (!chatbot.conversationId) return;
    fetchChatHistory(chatbot.conversationId);
  }, [chatbot.conversationId, fetchChatHistory]);

  useEffect(() => {
    const chatHistoryEle = chatHistoryContainer?.current;
    const lastMessageEle = chatMessagesRef?.current.slice(-1)[0];
    const lastMessageEleHeight = lastMessageEle?.offsetHeight || 0;
    const sh = chatHistoryEle?.scrollHeight || 0;
    const st = chatHistoryEle?.scrollTop || 0;
    const ch = chatHistoryEle?.clientHeight || 0;
    // Is near the bottom of the chat
    const nearBottom = sh - st - ch - lastMessageEleHeight <= 80;
    // If the new message is from the assistant
    if (nearBottom && messages.slice(-1)[0]) {
      scrollToBottom('instant');
    } else if (nearBottom) {
      scrollToBottom();
    } else if (chatbot.isOpen) {
      scrollToBottom();
    }
  }, [messages, chatHistory.status, chatbot.isOpen]);

  return (
    <div className={ `chat-history-container` } ref={ chatHistoryContainer }>
      { !messages?.length ? (
        <div className={ `center-items` }>
          <div>No conversation yet</div>
        </div>
      ) : (
        messages.map((message, idx, array) => (
          <Message
            useBubble={ true }
            key={ idx }
            index={ idx }
            chatMessagesRef={ chatMessagesRef }
            role={ message.role }
            contentText={ message.content?.text }
            theme={ company }
            contentResources={ message.content?.resources }
            metadata={ message.metadata }
            messageId={ message.messageId }
            sessionId={ message.sessionId }
            feedbackAction={ message.feedbackAction }
            feedbackContent={ message.feedbackContent }
            isError={ message.isError }
            verificationResult={ message.verificationResult }
            buttonsClickable={ array.length - idx === 1 } // only clickable if it's the last message
            handleClick={ (interruptQuery) => handleAsk(interruptQuery) }
            handleProductCategory={ (selectedProductCategory) => {
              const category = {
                value: selectedProductCategory,
                label: selectedProductCategory,
                isDisabled: false,
              };
              setProductCategory(category);
              productCategorySelect.current.setValue(category);
            } }
            handleProductComparison={ (products) => {
              setComparedProducts(products);
            } }
            handleFactCheck={ handleFactCheck }
            showFactCheckBtn={ message.sessionId == sessionId }
            status={ message.status }
            message={ message }
          />
        ))
      ) }
      <div ref={ chatBottom } id="bottom"></div>
    </div>
  );
};

export default ChatHistory;
