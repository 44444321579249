import React, { useState, useEffect, useRef, useCallback, useContext, useReducer } from 'react';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { extractProductFeatures } from '../adapters/restAPIServices';

// global user state
import UserContext from '../contexts/user/user'

// load assets
import userLogo from '../assets/user-solid.svg'

// styles
import '../styles/upload.scss';

const themes = {
  'AIA Demo': {
    backgroundColor: 'rgb(250, 249, 243)'
  },
  'NW Life': {
    backgroundColor: '#efefef'
  },
  'K Cash Demo': {
    backgroundColor: '#fff'
  },
}

const Upload = () => {

  const paragraphSet = 'NW Life'
  const { name } = useContext(UserContext)
  const [role, setRole] = useState('')
  const [productName, setProductName] = useState('')
  const [code, setCode] = useState('')
  const [company, setCompany] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [inputFileKey, setInputFileKey] = useState(1)
  const [extractingProductFeatures, setExtractingProductFeatures] = useState(false)

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  }

  const handleSubmit = async(event) => {
    event.preventDefault()
    setExtractingProductFeatures(true);
    await extractProductFeatures(company, code, productName, role, selectedFile);
    setInputFileKey(inputFileKey + 1)
    setSelectedFile(null)
    setExtractingProductFeatures(false)
    setRole('')
    setCode('')
    setCompany('')
    setProductName('')
  }

  return (
    <div className='upload-container' style={{ ...themes[paragraphSet] }}>
      <div className='title-bar'>
        <div className='title-options-bar'>
          {name ? <div><img className="user-logo" alt='user' src={userLogo}></img>{name}</div> : <div>Welcome back!</div>}
          <div>Upload product brochures</div>
        </div>
      </div>
      <form className='input-form' onSubmit={handleSubmit}>
        <div className='input-box-container'>
          <label className='form-field'>Product Code</label>
          <input type="text" value={code} placeholder="CEO5" onChange={(event) => { setCode(event.target.value); }} />
        </div>
        <div className='input-box-container'>
          <label className='form-field'>Product Name</label>
          <input type="text" value={productName} placeholder="CEO MEDICAL PLAN 5" onChange={(event) => { setProductName(event.target.value); }} />
        </div>
        <div className='input-box-container'>
          <label className='form-field'>Company</label>
          <input type="text" value={company} placeholder="aia" onChange={(event) => { setCompany(event.target.value); }} />
        </div>
        <div className='input-box-container'>
          <label className='form-field'>Role</label>
          <input type="text" value={role} placeholder="customer" onChange={(event) => { setRole(event.target.value); }}/>
        </div>
        <div className='upload-box-container'>
          <label className='form-field'>Document</label>
          <input type="file" onChange={onFileChange} key={inputFileKey}></input>
        </div>
        <input type="submit" value={extractingProductFeatures ? "Submitting..." : "Submit"} disabled={extractingProductFeatures || !(code && role && selectedFile)}/>
      </form>
    </div>
  );
};

export default Upload;
