import React from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Rout } from 'react-router-dom';
import './styles/common.scss';
import { ChatbotProvider } from '#contexts/chatbot';

// Pages
import Portal from "#pages/Portal";
import NotFound from '#pages/NotFound';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='*' element={ <NotFound /> } />
      <Route path='demo/'>
        <Route path=":company/:agentCode/portal" element={ <ChatbotProvider><Portal /></ChatbotProvider> } />
      </Route>
    </>

  ), {
}
);

function App() {
  return (
    <RouterProvider router={ router } />
  );
}

export default App;