import axios from 'axios';
import { hash, encrypt } from './cryptographyServices';
import { fetchEventSource, FetchEventSourceInit, EventStreamContentType } from '@microsoft/fetch-event-source';
// Configurations
import { agentMode } from '../config/config';

const { fetch: originalFetch } = window;

const backendHost = process.env.REACT_APP_BACKEND_HOST;
const tokens = {};

const fetchIntercept = async (url, options) => {
  // add access token (if accessToken is set)
  if (!options.headers['Authorization'] && tokens.accessToken) {
    options.headers['Authorization'] = 'Bearer ' + tokens.accessToken;
  }
  const response = await originalFetch(url, options);

  // retry if 401
  if (response.status === 401 && tokens.refreshToken) {
    const {
      success,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    } = await refreshAccessToken(tokens.refreshToken);
    if (success) {
      // set the new tokens
      addRequestInterceptor(newAccessToken);
      addResponseInterceptor(newRefreshToken);
      options.headers['Authorization'] = 'Bearer ' + newAccessToken;
      try {
        return await fetchIntercept(url, options);
      } catch (error) {
        console.log('Retry failed', error);
        throw error;
      }
    }
  }
  return response;
};


export async function getUserChatHistory() {
  try {
    let res = await axios.post(`${ backendHost }/api/chatbot/get-history`,);
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not get user chat history');
    throw e;
    // this should rightfully fail, it should be caught by logout() outside. the log is for debugging only
  }
}

export async function clearUserChatHistory(userId) {
  try {
    await axios.post(`${ backendHost }/api/chatbot/delete-history`, { userId });
  }
  catch (e) {
    console.log('Could not clear user chat history');
    console.log(e);
  }
}

export async function getProductMapping(company) {
  try {
    let res = await axios.get(`${ backendHost }/api/chatbot/product-mapping`, { params: { company } });
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not get product mapping');
    throw e;
  }
}

export async function getAllProducts() {
  try {
    let res = await axios.post(`${ backendHost }/api/product/get-all-products`);
    return res.data;
  }
  catch (e) {
    console.log('Could not get products');
    throw e;
  }
}

export async function makeQuery(content, messageId, scope, { numberOfPreviousMessages, previousMessageRoles, productCategories, productCodes, timestamp = new Date(), clarificationQuery, isCleanHistory } = {}) {
  const opt = {
    content,
    messageId,
    scope,
    options: {
      numberOfPreviousMessages,
      previousMessageRoles,
      productCategories,
      productCodes,
      timestamp,
      clarificationQuery,
      isCleanHistory,
    }
  };
  try {
    let res = await axios.post(`${ backendHost }/api/chatbot/query`, opt);
    let userResponse = res.data;
    return userResponse;
  }
  catch (e) {
    console.log('Could not ask question');
    console.log(e);
    return {
      success: false,
      errorResponse: {
        role: 'assistant',
        content: {
          text: e?.response?.status == 429 ? 'Maximum amount of requests reached. Please try again in a few minutes.' : 'An error has occurred. Please try again later.'
        },
        isError: true
      }
    };
  }
}

export async function makeQueryV2(
  content,
  messageId,
  scope,
  stream = false,
  {
    numberOfPreviousMessages,
    previousMessageRoles,
    productCategories,
    productCodes,
    timestamp = new Date(),
    clarificationQuery,
    isCleanHistory,
    mode = agentMode.SupportService
  } = {},
  {
    signal,
    onopen = async (response) => {
      if (
        response.ok &&
        response.headers.get('content-type') === EventStreamContentType
      ) {
        return; // everything's good
      } else if (
        response.status >= 400 &&
        response.status < 500 &&
        response.status !== 429
      ) {
        // client-side errors are usually non-retriable:
        throw new Error(response);
      }
      throw new Error(response);
    },
    onmessage,
    onclose,
    onerror = (err) => {
      console.error('Error in EventSource: ', err);
      throw err;
    },
  } = {}
) {
  const opt = {
    content,
    messageId,
    scope,
    stream,
    options: {
      numberOfPreviousMessages,
      previousMessageRoles,
      productCategories,
      productCodes,
      timestamp,
      clarificationQuery,
      isCleanHistory,
    }
  };
  const body = JSON.stringify(opt);
  try {
    let endpoint = `${ backendHost }/api/chatbot/`;
    switch (mode) {
      case agentMode.NormalAdvisor:
        endpoint += 'query';
        break;
      case agentMode.SupportService:
        endpoint += 'message-to-support-service';
        break;
      case agentMode.SalesAdvisor:
        endpoint += 'message-to-sales-advisor';
        break;
      default:
        endpoint += 'query';
        break;
    }
    await fetchEventSource(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
      body: body,
      onopen,
      onmessage,
      onclose,
      onerror,
      openWhenHidden: true, // Important!! Under the current design, `openWhenHidden` must set to true! This is because the EventSource is closed when the tab is hidden, and when the tab is visible again, a new EventSource with the same messageId is created, which would causes duplicate messageId error from DB.
      fetch: fetchIntercept,
    });
    console.log('Query Done');
  }
  catch (e) {
    console.log('Could not ask question');
    console.log(e);
    return {
      success: false,
      errorResponse: {
        role: 'assistant',
        content: {
          text: e?.response?.status == 429 ? 'Maximum amount of requests reached. Please try again in a few minutes.' : 'An error has occurred. Please try again later.'
        },
        isError: true
      }
    };
  }
}

export async function startNewTopic(content, messageId, timestamp = new Date()) {
  const opt = {
    content,
    messageId,
    timestamp,
  };
  try {
    let res = await axios.post(`${ backendHost }/api/chatbot/start-new-topic`, opt);
    let userResponse = res.data;
    return userResponse;
  }
  catch (e) {
    console.log('Could not start new topic');
    console.log(e);
    return { role: 'error', content: 'An error occurred. please try again later' };
  }
}

export async function login(username, password, isAdminLogin, loginDistrict) {
  console.log('Logging in');
  const encryptedHash = await encrypt(await hash(password));
  try {
    let res = await axios.post(`${ backendHost }/api/users/login`, {
      userId: username,
      password: encryptedHash,
      role: isAdminLogin ? "admin" : "user",
      district: loginDistrict
    });
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not login');
    console.log(e);
    if (e.response?.status === 401) {
      return { success: false, errorMessage: 'Incorrect username or password.' };
    }
    else if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false, errorMessage: e.message };
  }
}

export async function changePassword(username, oldPassword, newPassword) {
  const encryptedOldHash = encrypt(await hash(oldPassword));
  const encryptedNewHash = encrypt(await hash(newPassword));
  try {
    let res = await axios.post(`${ backendHost }/api/users/change-password`, {
      username: username,
      oldPassword: encryptedOldHash,
      newPassword: encryptedNewHash,
    });
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not change password');
    console.log(e);
    if (e.response?.status === 401) {
      return { success: false, errorMessage: 'Incorrect username or old password.' };
    }
    else if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

export async function forgotPassword(username) {
  try {
    let res = await axios.post(`${ backendHost }/api/users/forgot-password`, { userId: username });
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not forgot password');
    console.log(e);
    if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

export async function verifyToken(accessToken) {
  console.log('Verifying access token');
  try {
    let res = await axios.post(`${ backendHost }/api/users/verify-token`, {},
      { headers: { 'Authorization': 'Bearer ' + accessToken } });
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not verify token');
    console.log(e);
    if (e.response?.status === 401) {
      return { success: false, errorMessage: 'Session expired, please log in again' };
    }
    else if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

export async function refreshAccessToken(refreshToken) {
  console.log('Refreshing access token');
  try {
    let res = await axios.post(`${ backendHost }/api/users/refresh-access-token`, {},
      { headers: { 'Authorization': 'Bearer ' + refreshToken }, _isRetry: true }); // _isRetry prevents infinite refresh loop
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not refresh access token');
    console.log(e);
    return { success: false };
  }
}

export async function agreeToTnC() {
  try {
    let res = await axios.post(`${ backendHost }/api/users/agree-to-tnc`);
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not agree to TnC');
    console.log(e);
    return { success: false };
  }
}

export async function exportConversation(from, to) {
  try {
    const opt = {
      from, to
    };
    let res = await axios.post(`${ backendHost }/api/chatbot/export`, opt, { responseType: 'blob' });
    let userData = res.data;
    console.log(userData);
    return userData;
  }
  catch (e) {
    console.log('Could not export conversation');
    console.log(e);
    if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

export async function sendFeedback(sessionId, messageId, action = "", content = "") {
  try {
    const opt = {
      sessionId, messageId, action, content
    };
    let res = await axios.post(`${ backendHost }/api/chatbot/feedback`, opt);
    let userData = res.data;
    console.log(userData);
    return userData;
  }
  catch (e) {
    console.log('Could not send feedback');
    console.log(e);
    if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

export async function verifyRespond(messageId, question, company) {
  try {
    const opt = {
      previousMessageId: messageId,
      previousQuery: question,
      scope: company
    };
    let res = await axios.post(`${ backendHost }/api/chatbot/verify`, opt);
    let userData = res.data;
    console.log(userData);
    return userData;
  } catch (e) {
    console.log("Could not verify respond");
    console.log(e);
    if (e.response?.status === 500) {
      return {
        success: false,
        errorResponse: {
          role: "assistant",
          content: {
            text:
              e?.response?.status == 429
                ? "Maximum amount of requests reached. Please try again in a few minutes."
                : "An error has occurred. Please try again later.",
          },
          isError: true,
        },
      };
    }
    return { success: false };
  }
}

export async function extractProductFeatures(company, code, productName, role, file) {
  try {
    const opt = { company, role, code, productName, file };
    let res = await axios.post(`${ backendHost }/api/product/extract-product-features`, opt, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not extract product features:', e);
    if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

export async function sendProductFeaturesEmail(productCodes) {
  try {
    const opt = { productCodes };
    let res = await axios.post(`${ backendHost }/api/product/send-email`, opt);
    let userData = res.data;
    return userData;
  }
  catch (e) {
    console.log('Could not extract product features:', e);
    if (e.response?.status === 500) {
      return { success: false, errorMessage: 'An error has occurred. Please try again later.' };
    }
    return { success: false };
  }
}

// does not actually remove the interceptor, just remove the token to be used
export function removeRequestInterceptor() {
  delete tokens.accessToken;
  sessionStorage.removeItem('access_token');
}

// does not actually remove the interceptor, just remove the token to be used
export function removeResponseInterceptor() {
  delete tokens.refreshToken;
  sessionStorage.removeItem('refresh_token');
}

// does not actually add an interceptor, just set the token to be used
export function addRequestInterceptor(accessToken) {
  tokens.accessToken = accessToken;
  sessionStorage.setItem('access_token', accessToken);
}

// does not actually add an interceptor, just set the token to be used
export function addResponseInterceptor(refreshToken) {
  tokens.refreshToken = refreshToken;
  sessionStorage.setItem('refresh_token', refreshToken);
}

export async function exportCompareTable(tableData, logoSrc) {
  const opt = {
    tableData,
    logoSrc
  };
  let resp = await axios.post(
    `${ backendHost }/api/product/export-compare-table`,
    opt,
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
  return resp.data;
}

// add access token (if accessToken is set) 
function requestInterceptor(config) {
  if (!config.headers['Authorization'] && tokens.accessToken) {
    config.headers['Authorization'] = 'Bearer ' + tokens.accessToken;
  }
  return config;
}

// pass through if request is already an error
async function requestInterceptorError(error) {
  Promise.reject(error);
}

// pass through if response is already a success
function responseInterceptorSuccess(response) {
  return response;
}

// handle 401 (if refreshToken is set) 
async function responseInterceptorError(error) {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest?._isRetry && tokens.refreshToken) {
    originalRequest._isRetry = true;
    const { success, accessToken: newAccessToken, refreshToken: newRefreshToken } = await refreshAccessToken(tokens.refreshToken);
    if (success) {
      addRequestInterceptor(newAccessToken);
      addResponseInterceptor(newRefreshToken);
      originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
      try {
        const retriedRequest = await axios(originalRequest);
        retriedRequest.data._isRetry = true; // necessary in verifyToken flow
        return retriedRequest;
      }
      catch (e) {
        console.log('Retry failed', e);
        throw e;
      }
    }
  }
  console.log('Refresh failed');
  return Promise.reject(error);
}

// the interceptors are added here and will not be removed
axios.interceptors.request.use(requestInterceptor, requestInterceptorError);
axios.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError);