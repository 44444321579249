import React from "react";

import FilterBox from "./FilterBox";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import canLogo from "../../assets/product-comparison-can.svg";
import cannotLogo from "../../assets/product-comparison-cannot.svg";

import "./CompareTable.scss";

function preprocessValue(value) {
  if (value === "//can//") return "<img src=" + canLogo + " />";
  if (value === "//cannot//") return "<img src=" + cannotLogo + " />";
  return value;
}

const CompareTable = ({
  productFeatureData = [[]],
  filterValue,
  setFilterValue,
}) => {
  const backendHost = process.env.REACT_APP_BACKEND_HOST;
  const rowLength = productFeatureData.length;
  return (
    <div
      className="compare-table"
      style={ {
        gridTemplateColumns: `repeat(${ productFeatureData[0].length + 1 }, 1fr)`,
      } }
    >
      <div className="filter-box-item item">
        <FilterBox filterValue={ filterValue } setFilterValue={ setFilterValue } />
      </div>
      { productFeatureData.map((row, rowIdx) => {
        const isLastRow = rowLength === rowIdx + 1;
        return row.map((value, valueIdx) => {
          let classValue = "item";
          if (rowIdx === 0) {
            classValue = "item column-header";
            return (
              <div key={ `${ rowIdx }${ valueIdx }` } className={ classValue }>
                <a className="product-link" href={ backendHost + value.url } target="_blank">
                  { value.title }<br />{ value.name }
                </a>
              </div>
            );
          } else if (valueIdx === 0) {
            classValue = "item row-header";
          }
          if (isLastRow) classValue += " last-row";
          return (
            <div key={ `${ rowIdx }${ valueIdx }` } className={ classValue }>
              { value
                ? parse(
                  DOMPurify.sanitize(preprocessValue(value), {
                    USE_PROFILES: { html: true },
                  })
                )
                : " " }
            </div>
          );
        });
      }) }
    </div>
  );
};

export default CompareTable;
